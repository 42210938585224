import type { I18nDateFormatKeys } from '~/i18n.config'

export function useFormatRentalDate(format: I18nDateFormatKeys = 'long-date-hour') {
    const { locale, d } = useI18n()

    function formatDate(date: Date | string | undefined) {
        if (!date) return ''

        let result = d(date, format, locale.value)

        if (locale.value === 'fr') {
            result = result.replace(/:/, 'h') // 20:15 -> 20h15
            // result = result.replace(/, /, ' à ') // 2024, 08:00 -> 2024 à 08:00
        }

        return result
    }

    return formatDate
}

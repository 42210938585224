import { InternalRouteName } from '~/constants/internal-route-name'

interface ReservationParams {
    category?: string
    depTime?: string
    retTime?: string
    depAgency?: string
    vehicle?: string
}
type ParamKey = (keyof ReservationParams)

function getParams() {
    const route = useRoute()
    const { category, depTime, retTime, depAgency, vehicle } = route.params as Record<string, string | undefined>

    return { category, depTime, retTime, depAgency, vehicle }
}

export function useReservationLink(params?: ReservationParams) {
    const category = computed(() => params?.category || getParams().category)
    const depTime = computed(() => params?.depTime || getParams().depTime)
    const retTime = computed(() => params?.retTime || getParams().retTime)
    const depAgency = computed(() => params?.depAgency || getParams().depAgency)
    const vehicle = computed(() => params?.vehicle || getParams().vehicle)

    const allParams = computed(() => {
        return {
            category: category.value,
            depTime: depTime.value,
            retTime: retTime.value,
            depAgency: depAgency.value,
            vehicle: vehicle.value,
        }
    })

    function getListingRoute(params: ReservationParams) {
        const necessaryKeys = ['category', 'depTime', 'retTime', 'depAgency'] as ParamKey[]
        if (!necessaryKeys.every(key => !!params[key])) return undefined

        return {
            name: InternalRouteName.RESERVATION_LISTING,
            params: {
                category: params?.category,
                depTime: params?.depTime,
                retTime: params?.retTime,
                depAgency: params?.depAgency,
            },
        }
    }

    const listingRoute = computed(() => {
        return getListingRoute(allParams.value)
    })

    function getOptionsRoute(params: ReservationParams) {
        const necessaryKeys = ['category', 'depTime', 'retTime', 'depAgency', 'vehicle'] as ParamKey[]
        if (!necessaryKeys.every(key => !!params[key])) return undefined

        return {
            name: InternalRouteName.RESERVATION_OPTIONS,
            params,
        }
    }

    const optionsRoute = computed(() => {
        return getOptionsRoute(allParams.value)
    })

    function getRequestRoute(params: ReservationParams) {
        const necessaryKeys = ['category', 'depTime', 'retTime', 'depAgency', 'vehicle'] as ParamKey[]
        if (!necessaryKeys.every(key => !!params[key])) return undefined

        return {
            name: InternalRouteName.RESERVATION_REQUEST,
            params,
        }
    }

    const requestRoute = computed(() => {
        return getRequestRoute(allParams.value)
    })

    function getAccessoriesRoute(params: ReservationParams) {
        const necessaryKeys = ['category', 'depTime', 'retTime', 'depAgency', 'vehicle'] as ParamKey[]
        if (!necessaryKeys.every(key => !!params[key])) return undefined

        return {
            name: InternalRouteName.RESERVATION_ACCESSORIES,
            params,
        }
    }
    const accessoriesRoute = computed(() => {
        return getAccessoriesRoute(allParams.value)
    })

    function getSummaryRoute(params: ReservationParams) {
        const necessaryKeys = ['category', 'depTime', 'retTime', 'depAgency', 'vehicle'] as ParamKey[]
        if (!necessaryKeys.every(key => !!params[key])) return undefined

        return {
            name: InternalRouteName.RESERVATION_SUMMARY,
            params,
        }
    }

    const summaryRoute = computed(() => {
        return getSummaryRoute(allParams.value)
    })

    return {
        allParams,
        getOptionsRoute,
        listingRoute,
        requestRoute,
        optionsRoute,
        accessoriesRoute,
        summaryRoute,
    }
}
